import { logisticaValoracionService } from "@/_services";
export const logisticaValoracion = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    albaranes: {
      data: [],
      status: {},
    },
    valorar: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getValoracionesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getValoracionesSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getValoracionesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    generarAlbaranesRequest(state) {
      state.albaranes.status = {
        loading: true,
      };
    },
    generarAlbaranesSuccess(state, response) {
      state.albaranes.data = response;
      state.albaranes.status = {
        loaded: true,
      };
    },
    generarAlbaranesError(state, error) {
      state.albaranes.data = null;
      state.albaranes.status = {
        error,
      };
    },
    revalorarRequest(state) {
      state.valorar.status = {
        loading: true,
      };
    },
    revalorarSuccess(state, response) {
      state.valorar.data = response;
      state.valorar.status = {
        loaded: true,
      };
    },
    revalorarError(state, error) {
      state.valorar.data = null;
      state.valorar.status = {
        error,
      };
    },
  },
  actions: {
    getValoraciones({ commit }, request) {
      commit("getValoracionesRequest");
      logisticaValoracionService
        .getValoraciones(request.pagination, request.query)
        .then((response) => {
          commit("getValoracionesSuccess", response);
        })
        .catch((error) => {
          commit("getValoracionesError", error);
        });
    },
    revalorar({ commit }, request) {
      commit("revalorarRequest");
      logisticaValoracionService
        .revalorar(request)
        .then((response) => {
          commit("revalorarSuccess", response);
        })
        .catch((error) => {
          commit("revalorarError", error);
        });
    },
    generarAlbaranes({ commit }, request) {
      commit("generarAlbaranesRequest");
      logisticaValoracionService
        .generarAlbaranes(request)
        .then((response) => {
          commit("generarAlbaranesSuccess", response);
        })
        .catch((error) => {
          commit("generarAlbaranesError", error);
        });
    },
  },
};
