import { userService, franquiciaService } from "@/_services";

export const user = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
    editPass: {
      data: {},
      status: {},
    },
    oneShotToken: {
      data: {},
      status: {},
    },
    list: {
      data: [],
      status: {},
    },
    users: {
      data: [],
      status: {},
    },
    roles: {
      data: [],
      status: {},
    },
    nuevoUsuario: {
      data: [],
      status: {},
    },
    changeStateUser: {
      data: [],
      status: {},
    },
    enlace: {
      data: [],
      status: {},
    },
    reset: {
      data: [],
      status: {},
    },
  },
  mutations: {
    newUsuarioRequest(state) {
      state.nuevoUsuario.status = {
        creating: true,
      };
    },
    newUsuarioError(state, error) {
      state.nuevoUsuario.data = null;
      state.nuevoUsuario.status = {
        error,
      };
    },
    newUsuarioSuccess(state, envio) {
      state.nuevoUsuario.data = envio;
      state.nuevoUsuario.status = {
        created: true,
      };
    },

    changeStateUsuarioRequest(state) {
      state.changeStateUser.status = {
        loading: true,
      };
    },
    changeStateUsuarioError(state, error) {
      state.changeStateUser.data = null;
      state.changeStateUser.status = {
        error,
      };
    },
    changeStateUsuarioSuccess(state, envio) {
      state.changeStateUser.data = envio;
      state.changeStateUser.status = {
        loaded: true,
      };
    },

    loginUserRequest(state) {
      state.entity.status = {
        logging: true,
      };
    },
    loginUserError(state, error) {
      state.entity.data = {};
      state.entity.status = {
        error,
      };
    },
    loginUserSuccess(state, usuario) {
      state.entity.data = usuario;
      state.entity.status = {
        logged: true,
      };
    },

    changePasswordRequest(state) {
      state.editPass.status = {
        editing: true,
      };
    },
    changePasswordError(state, error) {
      state.editPass.data = {};
      state.editPass.status = {
        error,
      };
    },
    changePasswordSuccess(state, usuario) {
      state.editPass.data = usuario;
      state.editPass.status = {
        edited: true,
      };
    },

    oneShotTokenRequest(state) {
      state.oneShotToken.data = null;
      state.oneShotToken.status = {
        loaded: false,
      };
    },
    oneShotTokenError(state, error) {
      state.oneShotToken.data = null;
      state.oneShotToken.status = {
        loaded: true,
        error: error,
      };
    },
    oneShotTokenSuccess(state, token) {
      state.oneShotToken.data = token;
      state.oneShotToken.status = {
        loaded: true,
      };
    },

    getUsuariosRequest(state) {
      state.list.status = {
        lading: true,
      };
    },
    getUsuariosError(state, error) {
      state.list.data = {};
      state.list.status = {
        error,
      };
    },
    getUsuariosSuccess(state, usuario) {
      state.list.data = usuario;
      state.list.status = {
        loaded: true,
      };
    },

    getUsersFranquiciaRequest(state) {
      state.users.status = {
        loading: true,
      };
    },
    getUsersFranquiciaError(state, error) {
      state.users.data = {};
      state.users.status = {
        error,
      };
    },
    getUsersFranquiciaSuccess(state, usuario) {
      state.users.data = usuario;
      state.users.status = {
        loaded: true,
      };
    },

    getRolesRequest(state) {
      state.roles.status = {
        loading: true,
      };
    },
    getRolesError(state, error) {
      state.roles.data = {};
      state.roles.status = {
        error,
      };
    },
    getRolesSuccess(state, usuario) {
      state.roles.data = usuario;
      state.roles.status = {
        loaded: true,
      };
    },

    getEnlaceRequest(state) {
      state.enlace.status = {
        getting: true,
      };
    },
    getEnlaceError(state, error) {
      state.enlace.data = {};
      state.enlace.status = {
        error,
      };
    },
    getEnlaceSuccess(state, user) {
      state.enlace.data = user;
      state.enlace.status = {
        getted: true,
      };
    },

    resetPasswordRequest(state) {
      state.reset.status = {
        resetting: true,
      };
    },
    resetPasswordError(state, error) {
      state.reset.data = {};
      state.reset.status = {
        error,
      };
    },
    resetPasswordSuccess(state, user) {
      state.reset.data = user;
      state.reset.status = {
        reseted: true,
      };
    },
  },

  actions: {
    login({ commit }, req) {
      commit("loginUserRequest");
      userService
        .login(req.user, req.password)
        .then((res) => {
          if (res.roles.includes("FRANQUICIA")) {
            franquiciaService
              .loadFranquicia()
              .then((franquicia) => {
                commit("loginUserSuccess", res);
              })
              .catch((error) => {
                commit("loginUserError", error);
              });
          } else {
            commit("loginUserError", new Error("Acceso Denegado"));
          }
        })
        .catch((error) => {
          commit("loginUserError", error);
        });
    },
    changePassword({ commit }, body) {
      commit("changePasswordRequest");
      userService
        .changePassword(body)
        .then((res) => {
          commit("changePasswordSuccess", res);
        })
        .catch((error) => {
          commit("changePasswordError", error);
        });
    },
    oneShotToken({ commit }, body) {
      commit("oneShotTokenRequest");
      userService
        .oneShotToken(body)
        .then((res) => {
          commit("oneShotTokenSuccess", res["token"]);
        })
        .catch((error) => {
          commit("oneShotTokenError", error);
        });
    },
    getUsuarios({ commit }, req) {
      commit("getUsuariosRequest");
      userService
        .getUsuarios(req.filters, req.pagination)
        .then((usuarios) => {
          commit("getUsuariosSuccess", usuarios);
        })
        .catch((error) => {
          commit("getUsuariosError", error);
        });
    },
    usersFranquicia({ commit }, req) {
      commit("getUsersFranquiciaRequest");
      userService
        .usersFranquicia(req.id, req.filters, req.pagination, req.tipo)
        .then((users) => {
          commit("getUsersFranquiciaSuccess", users);
        })
        .catch((error) => {
          commit("getUsersFranquiciaError", error);
        });
    },
    getRoles({ commit }, roles) {
      commit("getRolesRequest");
      userService
        .getRoles(roles)
        .then((rols) => {
          commit("getRolesSuccess", rols);
        })
        .catch((error) => {
          commit("getRolesError", error);
        });
    },
    nuevoUsuario({ commit }, req) {
      commit("newUsuarioRequest");
      userService
        .nuevoUsuario(req.tipo, req.id, req.user)
        .then((env) => {
          commit("newUsuarioSuccess", env);
        })
        .catch((error) => {
          commit("newUsuarioError", error);
        });
    },
    cambiarEstadoUser({ commit }, user) {
      commit("changeStateUsuarioRequest");
      userService
        .cambiarEstadoUser(user)
        .then((usuario) => {
          commit("changeStateUsuarioSuccess", usuario);
        })
        .catch((error) => {
          commit("changeStateUsuarioError", error);
        });
    },
    getEnlaceRecuperacion({ commit }, body) {
      commit("getEnlaceRequest");
      userService
        .getEnlaceRecuperacion(body)
        .then((user) => {
          commit("getEnlaceSuccess", user);
        })
        .catch((error) => {
          commit("getEnlaceError", error);
        });
    },
    resetLogin({ commit }, request) {
      commit("resetPasswordRequest");
      userService
        .resetPassword(request.body, request.token)
        .then((user) => {
          commit("resetPasswordSuccess", user);
        })
        .catch((error) => {
          commit("resetPasswordError", error);
        });
    },
  },
};
