import createAxios from "./axiosHttpMarconi";

function getEfectoById(efectoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/efecto/${efectoId}`;
  return axios.get(urlPath);
}
function editEfecto(efectoId, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/efecto/${efectoId}`;
  return axios.put(urlPath, body);
}

function agruparEfecto(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/efectos/agrupar`;
  return axios.put(urlPath, body);
}

function impagarEfecto(efectoId, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/efecto/${efectoId}/impagar`;
  return axios.put(urlPath, body);
}
function getEfectos(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/efectos`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  if (filters && filters.fecha) {
    urlPath += `&fechaDesde=${filters.fecha[0]}`;
    urlPath += `&fechaHasta=${filters.fecha[1]}`;
  }
  if (filters && filters.numeroEfecto) {
    urlPath += `&numeroEfecto=${filters.numeroEfecto}`;
  }
  if (filters && filters.numeroFactura) {
    urlPath += `&numeroFactura=${filters.numeroFactura}`;
  }
  if (filters && filters.IdRemesa) {
    urlPath += `&IdRemesa=${filters.IdRemesa}`;
  }
  if (filters && filters.numeroRemesa) {
    urlPath += `&numeroRemesa=${filters.numeroRemesa}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }
  if (filters && filters.agrupacion) {
    urlPath += `&agrupacion=${filters.agrupacion}`;
  }
  if (filters && filters.formaPago) {
    urlPath += `&formaPago=${filters.formaPago}`;
  }
  if (filters && filters.impagados) {
    urlPath += `&impagado=${filters.impagados}`;
  }
  if (filters && filters.efectoAgrupador) {
    urlPath += `&efectoAgrupador=${filters.efectoAgrupador}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`;
  } else {
    urlPath += `&sort=fecha&order=descending`;
  }
  return axios.get(urlPath);
}
function liquidarEfectos(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/efectos/liquidar`;
  return axios.put(urlPath, body);
}
function getEfectosAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/efectos/excel?limit=2000`;

  if (filters && filters.fecha) {
    urlPath += `&fechaDesde=${filters.fecha[0]}`;
    urlPath += `&fechaHasta=${filters.fecha[1]}`;
  }
  if (filters && filters.numeroEfecto) {
    urlPath += `&numeroEfecto=${filters.numeroEfecto}`;
  }
  if (filters && filters.numeroFactura) {
    urlPath += `&numeroFactura=${filters.numeroFactura}`;
  }
  if (filters && filters.numeroRemesa) {
    urlPath += `&numeroRemesa=${filters.numeroRemesa}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }
  if (filters && filters.agrupacion) {
    urlPath += `&agrupacion=${filters.agrupacion}`;
  }
  if (filters && filters.formaPago) {
    urlPath += `&formaPago=${filters.formaPago}`;
  }
  if (filters && filters.impagados) {
    urlPath += `&impagado=${filters.impagados}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.efectoAgrupador) {
    urlPath += `&efectoAgrupador=${filters.efectoAgrupador}`;
  }
  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`;
  } else {
    urlPath += `&sort=fecha&order=descending`;
  }

  return axios.get(urlPath, {
    responseType: "blob",
  });
}
export const efectoService = {
  getEfectoById,
  editEfecto,
  getEfectos,
  impagarEfecto,
  liquidarEfectos,
  getEfectosAsExcel,
  agruparEfecto,
};
