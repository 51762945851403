import createAxios from "./axiosHttpMarconi";

function getNotificaciones(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/facturas/notificaciones`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=20";
  }

  urlPath += getFilters(filters);

  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }

  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }

  return axios.get(urlPath);
}

function getFilters(filters) {
  let urlPath = "";
  if (filters && filters.date) {
    urlPath += `&fechaDesde=${filters.date[0]}`;
    urlPath += `&fechaHasta=${filters.date[1]}`;
  }

  if (filters && filters.factura) {
    urlPath += `&factura=${filters.factura}`;
  }

  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }

  if (filters && filters.email) {
    urlPath += `&email=${filters.email}`;
  }

  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  return urlPath;
}

function notificar(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/facturas/notificaciones/enviar`;
  return axios.post(urlPath, request);
}

export const notificacionService = {
  getNotificaciones,
  notificar,
};
