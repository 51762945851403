import { incidenciaService } from "@/_services";

export const incidencia = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
    chats: {
      data: [],
      status: {},
    },
    patch: {
      data: {},
      status: {},
    },
    mensaje: {
      data: {},
      status: {},
    },
    mensajeDelete: {
      data: {},
      status: {},
    },
    mensajeEdit: {
      data: {},
      status: {},
    },
    adjunto: {
      data: {},
      status: {},
    },
    soluciones: {
      data: [],
      status: {},
    },
    incidenciaMultiple: {
      data: {},
      status: {},
    },
    incidenciaExcel: {
      data: {},
      status: {},
    },
  },
  mutations: {
    incidenciaExcelRequest(state) {
      state.incidenciaExcel.status = {
        loading: true,
      };
    },
    incidenciaExcelSuccess(state, pdf) {
      state.incidenciaExcel.data = pdf;
      state.incidenciaExcel.status = {
        loaded: true,
      };
    },
    incidenciaExcelError(state, error) {
      state.incidenciaExcel.data = null;
      state.incidenciaExcel.status = {
        error,
      };
    },
    IncidenciaMultipleRequest(state) {
      state.incidenciaMultiple.status = {
        changing: true,
      };
    },
    IncidenciaMultipleError(state, error) {
      state.incidenciaMultiple.data = {};
      state.incidenciaMultiple.status = {
        error,
      };
    },
    IncidenciaMultipleSuccess(state, response) {
      state.incidenciaMultiple.data = response;
      state.incidenciaMultiple.status = {
        changed: true,
      };
    },

    getIncidenciasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getIncidenciasError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getIncidenciasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getIncidenciaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getIncidenciaByIdError(state, error) {
      state.entity.data = {};
      state.entity.status = {
        error,
      };
    },
    getIncidenciaByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
    getSolucionesByIncidenciaRequest(state) {
      state.soluciones.status = {
        loading: true,
      };
    },
    getSolucionesByIncidenciaError(state, error) {
      state.soluciones.data = {};
      state.soluciones.status = {
        error,
      };
    },
    getSolucionesByIncidenciaSuccess(state, response) {
      state.soluciones.data = response;
      state.soluciones.status = {
        loaded: true,
      };
    },
    getIncidenciaChatsRequest(state) {
      state.chats.status = {
        loading: true,
      };
    },
    getIncidenciaChatsError(state, error) {
      state.chats.data = {};
      state.chats.status = {
        error,
      };
    },
    getIncidenciaChatsSuccess(state, response) {
      state.chats.data = response;
      state.chats.status = {
        loaded: true,
      };
    },
    patchIncidenciaRequest(state) {
      state.patch.status = {
        changing: true,
      };
    },
    patchIncidenciaError(state, error) {
      state.patch.data = {};
      state.patch.status = {
        error,
      };
    },
    patchIncidenciaSuccess(state, response) {
      state.patch.data = response;
      state.patch.status = {
        changed: true,
      };
    },
    postMensajeIncidenciaRequest(state) {
      state.mensaje.status = {
        sending: true,
      };
    },
    postMensajeIncidenciaError(state, error) {
      state.mensaje.data = {};
      state.mensaje.status = {
        error,
      };
    },
    postMensajeIncidenciaSuccess(state, response) {
      state.mensaje.data = response;
      state.mensaje.status = {
        sended: true,
      };
    },
    deleteMensajeIncidenciaRequest(state) {
      state.mensajeDelete.status = {
        deleting: true,
      };
    },
    deleteMensajeIncidenciaError(state, error) {
      state.mensajeDelete.data = {};
      state.mensajeDelete.status = {
        error,
      };
    },
    deleteMensajeIncidenciaSuccess(state, response) {
      state.mensajeDelete.data = response;
      state.mensajeDelete.status = {
        deleted: true,
      };
    },
    putMensajeIncidenciaRequest(state) {
      state.mensajeEdit.status = {
        editing: true,
      };
    },
    putMensajeIncidenciaError(state, error) {
      state.mensajeEdit.data = {};
      state.mensajeEdit.status = {
        error,
      };
    },
    putMensajeIncidenciaSuccess(state, response) {
      state.mensajeEdit.data = response;
      state.mensajeEdit.status = {
        edited: true,
      };
    },
    getAdjuntoIncidenciaRequest(state) {
      state.adjunto.status = {
        downloading: true,
      };
    },
    getAdjuntoIncidenciaError(state, error) {
      state.adjunto.data = {};
      state.adjunto.status = {
        error,
      };
    },
    getAdjuntoIncidenciaSuccess(state, response) {
      state.adjunto.data = response;
      state.adjunto.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getIncidencias({ commit }, request) {
      commit("getIncidenciasRequest");
      incidenciaService
        .getIncidencias(request.pagination, request.filters)
        .then((response) => {
          commit("getIncidenciasSuccess", response);
        })
        .catch((error) => {
          commit("getIncidenciasError", error);
        });
    },
    getIncidenciaById({ commit }, id) {
      commit("getIncidenciaByIdRequest");
      incidenciaService
        .getIncidenciaById(id)
        .then((response) => {
          commit("getIncidenciaByIdSuccess", response);
        })
        .catch((error) => {
          commit("getIncidenciaByIdError", error);
        });
    },
    getSolucionesByIncidencia({ commit }, id) {
      commit("getSolucionesByIncidenciaRequest");
      incidenciaService
        .getSolucionesByIncidencia(id)
        .then((response) => {
          commit("getSolucionesByIncidenciaSuccess", response);
        })
        .catch((error) => {
          commit("getSolucionesByIncidenciaError", error);
        });
    },
    getIncidenciaChats({ commit }, req) {
      commit("getIncidenciaChatsRequest");
      incidenciaService
        .getIncidenciaChats(req.id, req.pagination, req.filters)
        .then((response) => {
          commit("getIncidenciaChatsSuccess", response);
        })
        .catch((error) => {
          commit("getIncidenciaChatsError", error);
        });
    },
    patchIncidencia({ commit }, req) {
      commit("patchIncidenciaRequest");
      incidenciaService
        .patchIncidencia(req.id, req.body)
        .then((response) => {
          commit("patchIncidenciaSuccess", response);
        })
        .catch((error) => {
          commit("patchIncidenciaError", error);
        });
    },
    postMensaje({ commit }, req) {
      commit("postMensajeIncidenciaRequest");
      incidenciaService
        .postMensaje(req.id, req.body)
        .then((response) => {
          commit("postMensajeIncidenciaSuccess", response);
        })
        .catch((error) => {
          commit("postMensajeIncidenciaError", error);
        });
    },
    deleteChatIncidencia({ commit }, req) {
      commit("deleteMensajeIncidenciaRequest");
      incidenciaService
        .deleteChatIncidencia(req.idIncidencia, req.idChat)
        .then((response) => {
          commit("deleteMensajeIncidenciaSuccess", response);
        })
        .catch((error) => {
          commit("deleteMensajeIncidenciaError", error);
        });
    },
    putChatIncidencia({ commit }, req) {
      commit("putMensajeIncidenciaRequest");
      incidenciaService
        .putChatIncidencia(req.idIncidencia, req.idChat, req.body)
        .then((response) => {
          commit("putMensajeIncidenciaSuccess", response);
        })
        .catch((error) => {
          commit("putMensajeIncidenciaError", error);
        });
    },
    getAdjunto({ commit }, req) {
      commit("getAdjuntoIncidenciaRequest");
      incidenciaService
        .getAdjunto(req.idIncidencia, req.idChat)
        .then((response) => {
          commit("getAdjuntoIncidenciaSuccess", response);
        })
        .catch((error) => {
          commit("getAdjuntoIncidenciaError", error);
        });
    },
    IncidenciaMultiple({ commit }, body) {
      commit("IncidenciaMultipleRequest");
      incidenciaService
        .multipleAsignarIncidencia(body)
        .then((response) => {
          commit("IncidenciaMultipleSuccess", response);
        })
        .catch((error) => {
          commit("IncidenciaMultipleError", error);
        });
    },
    getIncidenciasAsExcel({ commit }, filters) {
      commit("incidenciaExcelRequest");
      incidenciaService
        .getIncidenciasAsExcel(filters)
        .then((excel) => {
          commit("incidenciaExcelSuccess", excel);
        })
        .catch((error) => {
          commit("incidenciaExcelError", error);
        });
    },
  },
};
