import createAxios from "./axiosHttpMarconi";

function getAgrupacionCliente() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/agrupaciones-clientes`;
  return axios.get(urlPath);
}
export const agrupacionClienteService = {
  getAgrupacionCliente,
};
