import { agrupacionClienteService } from "@/_services";

export const agrupacionCliente = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getAgrupacionClienteRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getAgrupacionClienteError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    getAgrupacionClienteSuccess(state, estados) {
      state.entity.data = estados;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getAgrupacionCliente({ commit }) {
      commit("getAgrupacionClienteRequest");
      agrupacionClienteService
        .getAgrupacionCliente()
        .then((estados) => {
          commit("getAgrupacionClienteSuccess", estados);
        })
        .catch((error) => {
          commit("getAgrupacionClienteError", error);
        });
    },
  },
};
