import {
  createAxiosLogistica,
  createAxiosHeadersLogistica,
} from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

export const tarifasLogisticaService = {
  getTarifas,
  deleteTarifa,
  postTarifa,
  updateTarifa,
  getTarifaById,
  getExcel,
};

function getTarifas(pagination, filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosLogistica();
  let urlPath = `logistica/tarifa`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deleteTarifa(tarifaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/tarifa/${tarifaId}`;
  return axios.delete(urlPath);
}

function postTarifa(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/tarifa`;
  return axios.post(urlPath, request);
}

function updateTarifa(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/tarifa/${id}`;
  return axios.put(urlPath, body);
}

function getTarifaById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/tarifa/${id}`;
  return axios.get(urlPath);
}

function getExcel(filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;

  const axios = createAxiosHeadersLogistica();
  let urlPath = `logistica/tarifa/excel`;
  const params = new URLSearchParams(filters);

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath, { responseType: "blob" }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition;
    return Promise.resolve({ blob: response.data, fileName: fileName });
  });
}
