import { tarifasLogisticaService } from "@/_services";
export const tarifaLogistica = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
    excel: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getTarifasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getTarifasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getTarifasError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deleteTarifaRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteTarifaError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteTarifaSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postTarifaRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postTarifaError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postTarifaSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateTarifaRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateTarifaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateTarifaSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getTarifaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getTarifaByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getTarifaByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
    getExcelRequest(state) {
      state.excel.status = {
        downloading: true,
      };
    },
    getExcelError(state, error) {
      state.excel.data = null;
      state.excel.status = {
        error,
      };
    },
    getExcelSuccess(state, response) {
      state.excel.data = response;
      state.excel.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getTarifas({ commit }, request) {
      commit("getTarifasRequest");
      tarifasLogisticaService
        .getTarifas(request.pagination, request.query)
        .then((response) => {
          commit("getTarifasSuccess", response);
        })
        .catch((error) => {
          commit("getTarifasError", error);
        });
    },
    deleteTarifa({ commit }, id) {
      commit("deleteTarifaRequest");
      tarifasLogisticaService
        .deleteTarifa(id)
        .then((res) => {
          commit("deleteTarifaSuccess", res);
        })
        .catch((error) => {
          commit("deleteTarifaError", error);
        });
    },
    postTarifa({ commit }, request) {
      commit("postTarifaRequest");
      tarifasLogisticaService
        .postTarifa(request)
        .then((res) => {
          commit("postTarifaSuccess", res);
        })
        .catch((error) => {
          commit("postTarifaError", error);
        });
    },
    updateTarifa({ commit }, request) {
      commit("updateTarifaRequest");
      tarifasLogisticaService
        .updateTarifa(request.id, request.body)
        .then((res) => {
          commit("updateTarifaSuccess", res);
        })
        .catch((error) => {
          commit("updateTarifaError", error);
        });
    },
    getTarifaById({ commit }, id) {
      commit("getTarifaByIdRequest");
      tarifasLogisticaService
        .getTarifaById(id)
        .then((res) => {
          commit("getTarifaByIdSuccess", res);
        })
        .catch((error) => {
          commit("getTarifaByIdError", error);
        });
    },
    getEtiquetas({ commit }, filters) {
      commit("getEtiquetasRequest");
      tarifasLogisticaService
        .getEtiquetas(filters)
        .then((res) => {
          commit("getEtiquetasSuccess", res);
        })
        .catch((error) => {
          commit("getEtiquetasError", error);
        });
    },
    getExcel({ commit }, filters) {
      commit("getExcelRequest");
      tarifasLogisticaService
        .getExcel(filters)
        .then((res) => {
          commit("getExcelSuccess", res);
        })
        .catch((error) => {
          commit("getExcelError", error);
        });
    },
  },
};
